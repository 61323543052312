const globalNavigation = {
    "content": [
    {
        "link": "/",
        "title": "Residential",
        "svgIcon": "house",
        "specialClass": null,
        "submenu": [
            {
                "link": "/sign-up",
                "title": "Sign up"
            },
            {
                "link": "/moving-house",
                "title": "Moving house"
            },
            {
                "link": "/natural-gas",
                "title": "Natural Gas"
            },
            {
                "link": "/lpg",
                "title": "LPG Cylinders",
                "hiddenByLpgDemerger": true
            },
            {
                "link": "/kwik-gas",
                "title": "Kwik-Gas",
                "hiddenByLpgDemerger": true
            }
        ]
    },
    {
        "link": "/business",
        "title": "Business",
        "svgIcon": "store",
        "specialClass": null,
        "submenu": [
            {
                "link": "/business/get-a-quote",
                "title": "Request a quote"
            },
            {
                "link": "/business/natural-gas",
                "title": "Natural Gas"
            },
            {
                "link": "/business/electricity",
                "title": "Electricity"
            },
            {
                "link": "/business/lpg",
                "title": "LPG Cylinders",
                "hiddenByLpgDemerger": true
            },
            {
                "link": "/transport/lng",
                "title": "Liquefied Natural Gas",
                "hiddenByLpgDemerger": true
            },
            {
                "link": "/business/specialty-gases",
                "title": "Specialty Gas",
                "hiddenByLpgDemerger": true
            }
        ]
    },
    {
        "link": "/about",
        "title": "About",
        "svgIcon": null,
        "specialClass": "mer-global-nav__menu-item--shunt-right",
        "submenu": [
            {
                "link": "/about",
                "title": "Our story"
            },
            {
                "link": "/about/people",
                "title": "Our people"
            },
            {
                "link": "/blog",
                "title": "Our blog"
            },
            {
                "link": "/about/community",
                "title": "Community"
            },
            {
                "link": "/about/carbon-offset",
                "title": "Carbon offsets"
            },
            {
                "link": "/about/media-releases",
                "title": "Media releases"
            }
        ]
    },
    {
        "link": "/locations",
        "title": "Locations",
        "svgIcon": null,
        "specialClass": null,
        "hiddenByLpgDemerger": true
    },
    {
        "link": "/help",
        "title": "Help",
        "svgIcon": null,
        "specialClass": null,
        "submenu": [
            {
                "link": "/help",
                "title": "Help Centre"
            },
            {
                "link": "/safety",
                "title": "Safety"
            },
            {
                "link": "/hardship",
                "title": "Hardship support"
            },
            {
                "link": "/help/policies/family-domestic-violence-support",
                "title": "Family and domestic violence support"
            }
        ]
    },
    {
        "link": "/contact",
        "title": "Contact",
        "svgIcon": null,
        "specialClass": null
    },
    {
        "link": "tel:132180",
        "title": "13 21 80 ",
        "svgIcon": "phone-fill",
        "specialClass": "call"
    },
    {
        "link": null,
        "title": "Search",
        "svgIcon": "search",
        "specialClass": "search"
    },
    {
        "link": "/login",
        "title": "My Account",
        "svgIcon": "circle-user",
        "specialClass": "my-account",
        "submenuClass": "mer-global-nav__submenu--right",
        "submenu": [
            {
                "link": "/login",
                "title": "Login"
            },
            {
                "link": "/pay-online",
                "title": "Pay a bill"
            },
            {
                "link": "/lpg/order-online",
                "title": "Order cylinders",
                "hiddenByLpgDemerger": true
            }
        ]
    }
    ]
  }

export default globalNavigation